import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const InfoWrapper = styled.div(
	({ theme: { breakpoints }, isFairownSubscription }) => css`
		font-size: 14px;
		line-height: 130%;
		margin: 8px ${isFairownSubscription ? "8px" : "0"};
		padding: 0;
		background: none;

		@media (min-width: ${breakpoints.md}) {
			margin: 8px 0;
		}
	`,
);
