import * as CheckoutActions from "@actions/checkout.actions";
import AlertsContainer from "@components/alerts/AlertsContainer";
import ConfirmOrderButton from "@components/app/ConfirmOrderButton";
import OrderItems from "@components/order/OrderItems";
import RenewalHeader from "@components/v2/components/Renewal/RenewalHeader/RenewalHeader";
import RenewalSteps from "@components/v2/components/Renewal/RenewalSteps/RenewalSteps";
import { SECTIONS_NAMES, STATUS_NAMES, STORE_IDS } from "@constants/constants";
import classNames from "classnames";
import React, { useRef, useState } from "react";
import { FormattedHTMLMessage, FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import AppLoader from "../app/loaders/AppLoader";
import ReactCheckbox from "../form/ReactCheckbox";
import Order from "../order/Order";
import Recipient from "../recipient/Recipient";
import Summary from "../summary/Summary";
import Terms from "../terms/Terms";
import Payment from "../v2/components/payments/Payment";

import Header from "./Header";
import ReceiptPage from "./receiptPage/ReceiptPage";

const ConfirmOrderSection = () => {
	const dispatch = useDispatch();
	const intl = useIntl();

	const sectionElement = useRef(null);

	const [highlightCheckbox, setHighlightCheckbox] = useState(false);

	const payment = useSelector((state) => state.payment);

	const summary = useSelector((state) => state.summary);

	const storeId = useSelector((state) => state.checkout.storeId);
	const agreedToTerms = useSelector((state) => state.checkout.agreedToTerms);

	const isFlexLayout =
		summary.subscriptionPaymentSummary && payment.subscriptionMethod;

	const handleAgreeTermsChange = () => {
		setHighlightCheckbox(false);
		dispatch(CheckoutActions.toggleAgreeTerms());
	};

	return (
		!summary.isHidden && (
			<>
				<section
					id={SECTIONS_NAMES.CONFIRMATION}
					ref={sectionElement}
					className={classNames("confirmation", {
						"flex-layout": isFlexLayout,
					})}
					aria-label={intl.formatMessage({ id: "finish.section.title" })}
				>
					{storeId === STORE_IDS.KOMPLETT_DK && (
						<section
							className={classNames("agree-to-terms", {
								highlight: highlightCheckbox,
							})}
						>
							<ReactCheckbox
								value={agreedToTerms}
								handleChange={handleAgreeTermsChange}
								automationId="denmark.agreeToTerms"
								id="denmark.agreeToTerms"
								label={
									<FormattedHTMLMessage id="summary.denmark.agreeToTerms" />
								}
							/>
						</section>
					)}
					<ConfirmOrderButton setHighlightCheckbox={setHighlightCheckbox} />
				</section>
				<section className="selling-points">
					<ul>
						{summary.sellingPoints.map((item, index) => {
							return (
								<li className="item" key={index}>
									<FormattedMessage id={item} />
								</li>
							);
						})}
					</ul>
				</section>
			</>
		)
	);
};

const App = () => {
	const subscriptionMethod = useSelector(
		(state) => state.payment.subscriptionMethod,
	);

	const customerType = useSelector((state) => state.recipient.customerType);

	const status = useSelector((state) => state.checkout.status);
	const cartUrl = useSelector((state) => state.checkout.cartUrl);
	const storeUrl = useSelector((state) => state.checkout.storeUrl);
	const storeId = useSelector((state) => state.checkout.storeId);
	const isDisabled = useSelector((state) => state.checkout.isDisabled);
	const isBlurred = useSelector((state) => state.checkout.isBlurred);
	const isLoading = useSelector((state) => state.checkout.isLoading);
	const isRenewalMode = useSelector((state) => state.checkout.isRenewalMode);

	const subscriptionPaymentSummary = useSelector(
		(state) => state.summary.subscriptionPaymentSummary,
	);

	const summaryIsHidden = useSelector((state) => state.summary.isHidden);

	const isFlexLayout = subscriptionPaymentSummary && subscriptionMethod;

	return (
		<div className={classNames("wrapper", { disabled: isDisabled })}>
			<Header
				showBackToCartLink={status !== STATUS_NAMES.PLACED}
				cartUrl={cartUrl}
				storeUrl={storeUrl}
				storeId={storeId}
			/>
			<AlertsContainer role="checkout.general" disabled={isDisabled} />
			<AppLoader show={isLoading} />
			<main
				className={classNames("main", {
					"flex-layout": isFlexLayout,
					"renewal-layout": isRenewalMode,
					loading: isLoading,
				})}
			>
				{isRenewalMode && (
					<>
						<RenewalSteps />
						<RenewalHeader />
					</>
				)}
				{status === STATUS_NAMES.PLACED && <ReceiptPage />}
				<Recipient />
				<Order />
				<Payment />
				<section
					id={SECTIONS_NAMES.SUMMARY}
					className="section-summary-and-items"
				>
					<section>
						<OrderItems />
						<Summary />
					</section>
				</section>
				<Summary
					className={classNames("mobile", {
						"flex-layout": isFlexLayout,
					})}
				/>
				<OrderItems className="mobile" />
				<ConfirmOrderSection />
				{isBlurred && <div className="blur" />}
			</main>
			{!summaryIsHidden && (
				<Terms
					trigger={<FormattedMessage id="footer.termsandconditions.link" />}
					customerType={customerType}
					storeId={storeId}
				/>
			)}
		</div>
	);
};

export default App;
