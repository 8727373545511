import { PAYMENT_METHOD_NAMES } from "@constants/constants";
import React from "react";
import { useIntl } from "react-intl";

const PaymentMethodDescription = ({ type, options }) => {
	const id = `payment.methods.${type}.paymentPlan`;
	const { formatMessage } = useIntl();
	const formatPricePerMonth = (value) =>
		formatMessage(
			{ id: "perMonth" },
			{
				value: formatMessage({ id: "currencySymbol" }, { value: value }),
			},
		);

	switch (type) {
		case PAYMENT_METHOD_NAMES.WALLEY_INSTALLMENT: {
			const formattedOptions = Object.values(options);
			const adminFee = [
				...new Set(
					formattedOptions.map((option) => option.paymentPlan.adminFee),
				),
			];
			const isAdminFeeEqual = adminFee.length === 1;

			return (
				<section className={`payment-method-description ${type}`}>
					<h1
						dangerouslySetInnerHTML={{
							__html: formatMessage({
								id: `payment.methods.${type}.paymentPlan.header`,
							}),
						}}
					/>
					<ul>
						{formattedOptions.map((option) => (
							<li
								className={"payment-method-description-option"}
								key={option.code}
								dangerouslySetInnerHTML={{
									__html: formatMessage(
										{ id: `payment.methods.${type}.paymentPlan.option` },
										{
											monthlyCost: formatPricePerMonth(
												option.paymentPlan.monthlyCost,
											),
											numberOfTerms: option.paymentPlan.numberOfTerms,
											nominalInterestRate:
												option.paymentPlan.nominalInterestRate,
											cost: option.cost,
										},
									),
								}}
							/>
						))}
					</ul>
					{isAdminFeeEqual && (
						<p
							dangerouslySetInnerHTML={{
								__html: formatMessage(
									{ id: `payment.methods.${type}.paymentPlan.adminFee` },
									{ adminFee: formatPricePerMonth(adminFee[0]) },
								),
							}}
						/>
					)}
					<p
						dangerouslySetInnerHTML={{
							__html: formatMessage({
								id: `payment.methods.${type}.paymentPlan.description`,
							}),
						}}
					/>
				</section>
			);
		}
		default: {
			return (
				<div
					className={"payment-method-description" + ` ${type}`}
					dangerouslySetInnerHTML={{
						__html: formatMessage({ id }),
					}}
				/>
			);
		}
	}
};

export default PaymentMethodDescription;
