import {
	CHECKOUT_LOADER_ENABLE,
	CHECKOUT_LOADER_DISABLE,
} from "@actions/checkout.actions";
import Spinner from "@components/app/loaders/spinner/Spinner";
import Checkbox from "@components/form/Checkbox";
import Modal from "@components/modal/Modal";
import {
	getMarketingConsent,
	setMarketingConsent,
} from "@components/payment/SubscriptionPayment/api";
import React, { useEffect, useReducer, useRef } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

const initialState = {
	isConsented: false,
	errorMessage: "",
	isFetching: true,
	isInitialFetchDone: false,
};

const ACTIONS = Object.freeze({
	SET_CONSENT: "SET_CONSENT",
	SET_IS_FETCHING: "SET_IS_FETCHING",
});

export const reducer = (state, action) => {
	switch (action.type) {
		case ACTIONS.SET_CONSENT:
			return {
				...state,
				isConsented: action.payload.isConsented,
				errorMessage: action.payload.errorMessage,
				isInitialFetchDone: true,
				isFetching: false,
			};
		case ACTIONS.SET_IS_FETCHING:
			return {
				...state,
				isFetching: action.payload,
			};
		default:
			return { ...state };
	}
};

const MarketingConsentModal = ({ isModalOpen, handleToggleModal }) => {
	const { formatMessage } = useIntl();
	const storeUrl = useSelector((state) => state.checkout.storeUrl);
	const apiHost = new URL(storeUrl).hostname;
	const consentContent = formatMessage({
		id: "Consents.FlexAgreement.Text",
	});
	const genericKey = useRef(0);
	const storeDispatch = useDispatch();

	const [state, dispatch] = useReducer(reducer, initialState);

	const onChange = async (e) => {
		storeDispatch({ type: CHECKOUT_LOADER_ENABLE });

		dispatch({ type: ACTIONS.SET_IS_FETCHING, payload: true });
		dispatch({
			type: ACTIONS.SET_CONSENT,
			payload: await setMarketingConsent({
				apiHost,
				isConsented: e.target.checked,
				consentContent,
			}),
		});

		storeDispatch({ type: CHECKOUT_LOADER_DISABLE });
	};

	useEffect(() => {
		const InitConsentState = async () => {
			dispatch({
				type: ACTIONS.SET_CONSENT,
				payload: await getMarketingConsent({
					apiHost,
				}),
			});
		};

		if (!state.isInitialFetchDone) {
			dispatch({ type: ACTIONS.SET_IS_FETCHING, payload: true });
			InitConsentState();
		}
	}, [apiHost, state.isInitialFetchDone]);

	const showSpinner = !state.isInitialFetchDone;
	const showErrorMessage = state.errorMessage;

	return (
		<Modal
			isOpen={isModalOpen}
			onRequestClose={handleToggleModal}
			className="marketing-consent-modal"
			aria-labelledby="financePaymentTermsModalHeader"
			contentLabel="financePaymentTermsModalContent"
		>
			<section className="marketing-consent-modal-content">
				<h1 className="header">
					{formatMessage({
						id: "Consents.FlexAgreement.Text.Header",
					})}
				</h1>
				{showSpinner && <Spinner type="component" />}
				{showErrorMessage && (
					<p className="error-message">
						{formatMessage(
							{
								id: state.errorMessage,
							},
							{
								content: (...str) => {
									return <span key={++genericKey.current}>{str}</span>;
								},
								a: (...str) => (
									<a
										key={++genericKey.current}
										rel="noreferrer"
										target="_blank"
										href={formatMessage({
											id: "Consents.FlexAgreement.Error.Link",
										})}
									>
										{str}
									</a>
								),
							},
						)}
					</p>
				)}
				{!showSpinner && !showErrorMessage && (
					<section>
						<span
							dangerouslySetInnerHTML={{
								__html: formatMessage({
									id: "Consents.FlexAgreement.Text.Description",
								}),
							}}
						/>
						<Checkbox
							id="flexMarketingConsent"
							className="consents-checkbox"
							handleChange={onChange}
							name="flexMarketingConsent"
							value={state.isConsented}
							disabled={state.isFetching}
							label={formatMessage({
								id: "Consents.FlexAgreement.Text",
							})}
						/>
					</section>
				)}
			</section>
		</Modal>
	);
};

export default MarketingConsentModal;
