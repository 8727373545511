const callApi = (path, method, data) => {
	const headers = {
		Accept: "application/json",
		"Content-Type": "application/json",
	};
	const init = {
		method,
		credentials: "include",
		headers: new Headers(headers),
		...(data ? { body: JSON.stringify(data) } : {}),
	};

	return fetch(path, init);
};

export const getMarketingConsent = async ({ apiHost }) => {
	const errorMessage = "Consents.FlexAgreement.Error.Text";
	try {
		const response = await callApi(
			`https://${apiHost}/consents/customerconsents/flex/exists`,
			"GET",
		);

		if (!response.ok) {
			return { isConsented: false, errorMessage };
		}

		const result = await response.json();
		/*
			Result object structure:
 			IsSuccess: bool - true if the user has made at least one purchase through Flex.
 			Content: bool - true if the user has consented to Flex Marketing Consent.
		*/
		const isConsented = response.ok && result.isSuccess && result.content;

		return { isConsented, error: null };
	} catch (e) {
		return { isConsented: false, errorMessage };
	}
};

export const setMarketingConsent = async ({
	apiHost,
	consentContent,
	isConsented,
}) => {
	const errorMessage = "Consents.FlexAgreement.Error.Text";
	const consent = "FlexAgreement";

	try {
		const response = await callApi(
			`https://${apiHost}/consents/customerconsents/flex/update`,
			"POST",
			{
				consentContent: consentContent,
				consented: isConsented,
				type: consent,
			},
		);

		return response.ok
			? { isConsented: isConsented, errorMessage: null }
			: { isConsented: false, errorMessage };
	} catch (e) {
		return { isConsented: !isConsented, errorMessage };
	}
};
