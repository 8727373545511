import MarketingConsentModal from "@components/payment/SubscriptionPayment/MarketingConsentModal";
import React, { useState } from "react";
import { useIntl } from "react-intl";

const MarketingConsent = () => {
	const { formatMessage } = useIntl();
	const [isModalOpen, setIsModalOpen] = useState(false);

	const handleToggleModal = (e) => {
		e.preventDefault();
		setIsModalOpen(!isModalOpen);
	};

	return (
		<section className="marketing-consent">
			<p>
				{formatMessage({ id: "payments.fiarownsubscription.consent" })}{" "}
				<button aria-expanded={isModalOpen} onClick={handleToggleModal}>
					{formatMessage({ id: "payments.fiarownsubscription.consent.button" })}
				</button>
			</p>
			{isModalOpen && (
				<MarketingConsentModal
					handleToggleModal={handleToggleModal}
					isModalOpen={isModalOpen}
				/>
			)}
		</section>
	);
};

export default MarketingConsent;
