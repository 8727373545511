import { ALERT_LEVELS } from "@constants/constants";
import {
	GTM_EVENT_NAMES,
	pushToDataLayerAsync,
	setInitialAnalyticsSent,
} from "@utils/GTMUtils";
import { processLogisticUnits } from "@utils/jsonUtils";

import * as AlertActions from "../actions/alert.actions";
import * as PaymentMethodsActions from "../actions/payment/methods.actions";
import * as RecipientActions from "../actions/recipient.actions";
import * as ShippingActions from "../actions/shipping.actions";
import initial from "../reducers/createInitialGtmData";

import authFetch from "./utils/common/authFetch";
import getCheckoutId from "./utils/common/getCheckoutId";
import handleErrorStatus from "./utils/common/handleErrorStatus";
import noAuthFetch from "./utils/common/noAuthFetch";

/* action types */

export const CHECKOUT_CONFIRM_DONE = "CHECKOUT_CONFIRM_DONE";
export const CHECKOUT_CONFIRM_FAILED = "CHECKOUT_CONFIRM_FAILED";
export const CHECKOUT_CONFIRM_REQUESTED = "CHECKOUT_CONFIRM_REQUESTED";

export const CHECKOUT_FETCH_REQUESTED = "CHECKOUT_FETCH_REQUESTED";
export const CHECKOUT_FETCH_DONE = "CHECKOUT_FETCH_DONE";
export const CHECKOUT_FETCH_FAILED = "CHECKOUT_FETCH_FAILED";

export const CHECKOUT_LOADED = "CHECKOUT_LOADED";

export const CHECKOUT_URLS_REQUESTED = "CHECKOUT_URLS_REQUESTED";
export const CHECKOUT_URLS_DONE = "CHECKOUT_URLS_DONE";
export const CHECKOUT_URLS_FAILED = "CHECKOUT_URLS_FAILED";
export const CHECKOUT_URLS_CANCELLED = "CHECKOUT_URLS_CANCELLED";

export const CHECKOUT_INIT_ANALYTICS_SEND_REQUESTED =
	"CHECKOUT_INIT_ANALYTICS_SEND_REQUESTED";
export const CHECKOUT_INIT_ANALYTICS_SEND_DONE =
	"CHECKOUT_INIT_ANALYTICS_SEND_DONE";
export const CHECKOUT_INIT_ANALYTICS_SEND_FAILED =
	"CHECKOUT_INIT_ANALYTICS_SEND_FAILED";

export const CHECKOUT_COMPL_ANALYTICS_SEND_REQUESTED =
	"CHECKOUT_COMPL_ANALYTICS_SEND_REQUESTED";
export const CHECKOUT_COMPL_ANALYTICS_SEND_DONE =
	"CHECKOUT_COMPL_ANALYTICS_SEND_DONE";
export const CHECKOUT_COMPL_ANALYTICS_SEND_FAILED =
	"CHECKOUT_COMPL_ANALYTICS_SEND_FAILED";

export const CHECKOUT_SET_INITIAL_DATA = "CHECKOUT_SET_INITIAL_DATA";

export const LOCALE_CHANGED = "LOCALE_CHANGED";

export const CHECKOUT_BLUR_ENABLE = "CHECKOUT_BLUR_ENABLE";
export const CHECKOUT_BLUR_DISABLE = "CHECKOUT_BLUR_DISABLE";

export const CHECKOUT_LOADER_ENABLE = "CHECKOUT_LOADER_ENABLE";
export const CHECKOUT_LOADER_DISABLE = "CHECKOUT_LOADER_DISABLE";

export const CHECKOUT_DISABLE_CONFIRM_BUTTON =
	"CHECKOUT_DISABLE_CONFIRM_BUTTON";
export const CHECKOUT_ENABLE_CONFIRM_BUTTON = "CHECKOUT_ENABLE_CONFIRM_BUTTON";

export const CHECKOUT_TOGGLE_AGREE_TERMS = "CHECKOUT_TOGGLE_AGREE_TERMS";

const sendPreselectedMethodsToGTM = async (json) => {
	const { payment, order } = json;
	const parsedLogisticUnits = processLogisticUnits(order);

	if (parsedLogisticUnits.logisticUnits?.length) {
		const {
			shipping: {
				currentMethod: { typeCode },
				currentOption: { transporter, deliveryMethodId },
			},
		} = parsedLogisticUnits.logisticUnits[0];

		await pushToDataLayerAsync({
			data: {
				event: GTM_EVENT_NAMES.DELIVERY_METHOD_DEFAULT,
				selected: transporter
					? `${typeCode} ${deliveryMethodId} ${transporter}`
					: typeCode,
			},
		});
	}

	if (payment?.directPayment) {
		await pushToDataLayerAsync({
			data: {
				event: GTM_EVENT_NAMES.PAYMENT_METHOD_DEFAULT,
				selected: json.payment.directPayment.selectedMethod,
			},
		});
	}

	if (payment?.subscriptionPayment) {
		await pushToDataLayerAsync({
			data: {
				event: GTM_EVENT_NAMES.PAYMENT_METHOD_DEFAULT,
				selected: json.payment.subscriptionPayment.type,
			},
		});
	}
};

export const sendInitialAnalyticsOnInit = async (checkoutResponseData) => {
	if (checkoutResponseData.hasSentCheckoutInitiatedToAnalytics) {
		return;
	}

	try {
		const gtmInitialData = initial(checkoutResponseData);

		await pushToDataLayerAsync({
			data: gtmInitialData,
			throwAfterFailed: true,
		});

		await setInitialAnalyticsSent();
		await sendPreselectedMethodsToGTM(checkoutResponseData);
	} catch (e) {
		// eslint-disable-next-line no-console
		console.warn("Initial analytics send failed.");
	}
};

/* action creators */
export const fetchCheckout = () => async (dispatch, getState) => {
	try {
		const {
			recipient: { isEditing },
		} = getState();

		dispatch({ type: CHECKOUT_FETCH_REQUESTED });

		const response = await authFetch({
			url: `api/checkouts/${getCheckoutId()}`,
		});
		const checkoutResponseData = await response.json();

		// TODO: change name of "json" to something nice in all reducers.
		dispatch({ type: CHECKOUT_FETCH_DONE, json: checkoutResponseData });
		await sendInitialAnalyticsOnInit(checkoutResponseData);

		dispatch(
			PaymentMethodsActions.setPayment({
				payment: checkoutResponseData.payment || {},
			}),
		);
		await dispatch(
			RecipientActions.setEditModeVisibility(
				isEditing || !checkoutResponseData.recipient.isValid,
			),
		);
		await dispatch(
			PaymentMethodsActions.setSectionValidityWhenCoveredByBonusPoints(),
		);
		await dispatch(
			PaymentMethodsActions.setSectionValidityWhenCoveredByGiftCard(),
		);
		await dispatch(
			PaymentMethodsActions.setSectionValidityWhenCoveredByGiftCardsAndBonusPoints(),
		);
		dispatch({ type: CHECKOUT_LOADED });
	} catch (e) {
		handleErrorStatus(e, CHECKOUT_FETCH_FAILED, dispatch);
	}
};

export function getCheckoutUrls() {
	const checkoutId = getCheckoutId();

	return (dispatch) => {
		dispatch({ type: CHECKOUT_URLS_REQUESTED });
		if (checkoutId) {
			noAuthFetch({ url: `api/navigation/${checkoutId}` })
				.then((response) => response.json())
				.then((json) => dispatch({ type: CHECKOUT_URLS_DONE, json }))
				.catch((error) =>
					handleErrorStatus(error, CHECKOUT_URLS_FAILED, dispatch),
				);
		} else {
			dispatch({ type: CHECKOUT_URLS_CANCELLED });
		}
	};
}

export function setCompleteAnalyticsSent() {
	return (dispatch) => {
		dispatch(showLoader());

		dispatch({ type: CHECKOUT_COMPL_ANALYTICS_SEND_REQUESTED });

		return authFetch({
			url: `api/checkouts/${getCheckoutId()}/analytics/complete`,
			method: "PUT",
		})
			.then(dispatch({ type: CHECKOUT_COMPL_ANALYTICS_SEND_DONE }))
			.catch((error) =>
				handleErrorStatus(
					error,
					CHECKOUT_COMPL_ANALYTICS_SEND_FAILED,
					dispatch,
				),
			)
			.finally(dispatch(hideLoader()));
	};
}

export function setInitialData(data) {
	return (dispatch) => {
		dispatch({ type: CHECKOUT_SET_INITIAL_DATA, data });
	};
}

export const enableBlur = () => (dispatch) => {
	dispatch({ type: CHECKOUT_BLUR_ENABLE });
	dispatch(ShippingActions.disableSection());
	dispatch(PaymentMethodsActions.disableSection());
};

export const disableBlur = () => (dispatch) => {
	dispatch({ type: CHECKOUT_BLUR_DISABLE });
	dispatch(ShippingActions.enableSection());
	dispatch(PaymentMethodsActions.enableSection());
};

export const showLoader = () => (dispatch) => {
	dispatch({ type: CHECKOUT_LOADER_ENABLE });
};

export const hideLoader = () => (dispatch) => {
	dispatch({ type: CHECKOUT_LOADER_DISABLE });
};

export const disableConfirmButton = () => (dispatch) => {
	dispatch({ type: CHECKOUT_DISABLE_CONFIRM_BUTTON });
};

export const alertCountDownExpired = () => (dispatch) => {
	dispatch(
		AlertActions.addAlert(
			"checkout.shipping.countdown",
			ALERT_LEVELS.ERROR,
			"delivery.window.countdown.expired",
		),
	);
};

export const disableExpiredCheckout =
	({ lockConfirmButton }) =>
	(dispatch) => {
		lockConfirmButton && dispatch(disableConfirmButton());
		dispatch(alertCountDownExpired());
	};

export const enableConfirmButton = () => (dispatch) => {
	dispatch(
		AlertActions.removeAlert(
			"checkout.shipping.countdown",
			"delivery.window.countdown.expired",
		),
	);
	dispatch({ type: CHECKOUT_ENABLE_CONFIRM_BUTTON });
};

export const toggleAgreeTerms = () => (dispatch) => {
	dispatch({ type: CHECKOUT_TOGGLE_AGREE_TERMS });
};
