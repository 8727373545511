import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useCallback, useEffect } from "react";

const AppLoader = ({ show }) => {
	const handleClick = useCallback((e) => {
		e.stopPropagation();
	}, []);

	useEffect(() => {
		if (show) {
			document.querySelector("body").addEventListener("click", handleClick);
		} else {
			document.querySelector("body").removeEventListener("click", handleClick);
		}

		return () => {
			document.querySelector("body").removeEventListener("click", handleClick);
		};
	}, [handleClick, show]);

	return (
		<div className={classNames("app-loader", { show })}>
			{show && (
				<div className="throbber" role="progressbar">
					<svg className="circular" data-automationid="circular">
						<circle className="path" cx="40" cy="40" r="38" />
					</svg>
				</div>
			)}
		</div>
	);
};

AppLoader.propTypes = {
	show: PropTypes.bool,
};

export default AppLoader;
