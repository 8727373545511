import * as CheckoutActions from "@actions/checkout.actions";
import { setSubscriptionSectionValidity } from "@actions/payment/methods.actions";
import * as SummaryActions from "@actions/summary.actions";
import SpinningCircle from "@components/button/loaders/SpinningCircle";
import { SECTIONS_NAMES, STORE_IDS, TYPECODES } from "@constants/constants";
import { GTM_EVENT_NAMES, pushToDataLayerAsync } from "@utils/GTMUtils";
import classNames from "classnames";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

const ConfirmOrderButton = ({ setHighlightCheckbox }) => {
	const dispatch = useDispatch();
	const intl = useIntl();

	const [disableConfirmButton, setDisableConfirmButton] = useState(false);

	const payment = useSelector((state) => state.payment);
	const isPaymentValid = useSelector((state) => state.payment.isValid);

	const summary = useSelector((state) => state.summary);

	const isRecipientValid = useSelector((state) => state.recipient.isValid);

	const storeId = useSelector((state) => state.checkout.storeId);
	const isBlurred = useSelector((state) => state.checkout.isBlurred);
	const agreedToTerms = useSelector((state) => state.checkout.agreedToTerms);
	const isConfirmButtonDisabled = useSelector(
		(state) => state.checkout.isConfirmButtonDisabled,
	);
	const isCoveredByGiftCard = useSelector(
		(state) => state.checkout.isCoveredByGiftCard,
	);
	const isCoveredByBonusPoints = useSelector(
		(state) => state.checkout.isCoveredByBonusPoints,
	);

	const isCoveredByGiftCardsAndBonusPoints = useSelector(
		(state) => state.checkout.isCoveredByGiftCardsAndBonusPoints,
	);

	const porterBuddyInstructions = useSelector(
		(state) => state.order.porterBuddyInstructions,
	);
	const typeCode = useSelector(
		(state) => state.order.logisticUnits[0]?.shipping.selectedShipping.typeCode,
	);
	const isOrderValid = useSelector((state) => state.order.isValid);

	const isPorterBuddySelected = typeCode === TYPECODES.PORTER;
	const appValidationStatus = [
		{ name: SECTIONS_NAMES.RECIPIENT, isValid: isRecipientValid },
		{ name: SECTIONS_NAMES.ORDER, isValid: isOrderValid },
		{ name: SECTIONS_NAMES.PAYMENT, isValid: isPaymentValid },
	];
	const isRenewalMode = useSelector((state) => state.checkout.isRenewalMode);

	const renewalSubmitButtonLabelId = "submit.with.subscription.renewal";
	const submitButtonLabelId = summary.subscriptionPaymentSummary
		? "submit.with.subscription"
		: "submit";

	const handleClickConfirmButton = async (e) => {
		e.preventDefault();
		e.stopPropagation();

		const consents = [];

		if (storeId === STORE_IDS.KOMPLETT_DK && !agreedToTerms) {
			setHighlightCheckbox(true);
			return;
		}

		if (isConfirmButtonDisabled) {
			dispatch(CheckoutActions.alertCountDownExpired());
			return;
		}

		if (payment.subscriptionMethod) {
			if (!payment.subscriptionMethod.consent.isConsentChecked) {
				dispatch(setSubscriptionSectionValidity(false)); // make test for it without props change

				return;
			}

			consents.push(payment.subscriptionMethod.consent.description);
		}

		let isEnabled = appValidationStatus.every((section) => section.isValid);
		const _porterBuddyInstructions = isPorterBuddySelected
			? porterBuddyInstructions
			: null;

		if (isEnabled) {
			setDisableConfirmButton(true);

			try {
				await pushToDataLayerAsync({
					data: {
						event: GTM_EVENT_NAMES.CONFIRM_ORDER_CLICK,
					},
				});

				await dispatch(
					SummaryActions.confirmCheckout({
						isCoveredByGiftCard,
						isCoveredByBonusPoints,
						isCoveredByGiftCardsAndBonusPoints,
						payment,
						intl,
						porterBuddyInstructions: _porterBuddyInstructions,
						consents,
					}),
				);
			} finally {
				setDisableConfirmButton(false);
			}
		} else {
			const invalidSections = appValidationStatus
				.filter((status) => !status.isValid)
				.map((element) => element.name);

			if (invalidSections.length > 0) {
				document.getElementById(invalidSections[0]).scrollIntoView();

				if (invalidSections[0] === SECTIONS_NAMES.PAYMENT) {
					document
						.getElementById(invalidSections[0])
						.querySelectorAll("form > section:not(.confirmations) input")
						.forEach((input) => {
							input.focus();
						});
				}
			}
		}
	};

	return (
		<button
			className={classNames("confirm-button", {
				"is-sending": summary.isSending,
				disabled:
					isConfirmButtonDisabled ||
					(storeId === STORE_IDS.KOMPLETT_DK && !agreedToTerms),
			})}
			onClick={handleClickConfirmButton}
			tabIndex={isBlurred ? -1 : 0}
			disabled={disableConfirmButton}
			data-automationid="confirmButton"
		>
			{summary.isSending && <SpinningCircle className="loader" />}
			{intl.formatMessage(
				{
					id: isRenewalMode ? renewalSubmitButtonLabelId : submitButtonLabelId,
				},
				{
					value: summary.subscriptionPaymentSummary
						? null
						: summary.directPaymentSummary.total.value,
				},
			)}
		</button>
	);
};

export default ConfirmOrderButton;
