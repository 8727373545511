import PropTypes from "prop-types";
import React from "react";

const SpinningCircle = ({ className }) => {
	return (
		<span className={className} data-testid="spinning-circle">
			<svg className="circular">
				<circle className="path" cx="15" cy="15" r="12" />
			</svg>
		</span>
	);
};

SpinningCircle.propTypes = {
	className: PropTypes.string,
};

export default SpinningCircle;
